"use client";

import React, { useEffect, useRef, useState } from "react";
import HeadingPill from "../components/HeadingPill";
import bg from "../../public/images/partner_bg.svg";
import cardBlur from "../../public/images/partner_cardBlur.svg";
import capabilities from "../../public/images/capabilities.svg";
import influencers from "../../public/images/influencers.svg";
import data_providers from "../../public/images/data_providers.svg";
import communities from "../../public/images/communities.svg";
import { Button } from "@/components/ui/button";
import Image, { StaticImageData } from "next/image";
import { Card } from "@/components/ui/card";
import gsap from "gsap";
import ConfettiExplosion from "react-confetti-explosion";
import { ScrollTrigger } from "gsap/all";
import { useGSAP } from "@gsap/react";

gsap.registerPlugin(ScrollTrigger);

export default function Partners() {
  const [isExploding, setIsExploding] = useState(false);
  const contRef = useRef(null);

  const partnerList: CardTypes[] = [
    {
      img: capabilities,
      heading: "Utilities",
      para: "Advanced AI trading, research, and automation, novel AI abilities in Web2, Web3 and beyond. ",
      className: " -900:flex-[1_1_48%]",
    },
    {
      img: influencers,
      heading: "Influencers",
      para: "We need influencers / coaches / educators / strategy providers / tips providers for the PAAL Ecosystem",
      className: " -900:flex-[1_1_48%]",
    },
    {
      img: data_providers,
      heading: "Data Providers",
      para: "Skilled in information, content, insights, knowledge, statistics, analytics, predictions",
      className: " -900:flex-[1_1_48%] -600:pb-[30px]",
    },
    {
      img: communities,
      heading: "Communities",
      para: "Professional communities of interest, market trading groups, sports betting groups, gaming groups, crypto token groups, central trading exchanges, marketplaces, brands, educators, information hubs/portals",
      className: "max-w-[347px] -900:flex-[1_1_48%] -600:pb-[30px]",
    },
  ];

  useGSAP(() => {
    ScrollTrigger.create({
      trigger: contRef.current,
      start: "top 65%",
      // markers: true,
      animation: gsap.to(contRef.current, {
        onStart: () => {
          setIsExploding(true);
          console.log('exploded')
        },
      }),
    });
  }, []);

  return (
    <section
      ref={contRef}
      id="partners"
      className="mt-[356px] -600:mt-[309px] pt-[66px] flex flex-col items-center relative px-[20px] overflow-hidden"
    >
      {/* Confettin animation */}
      {/* {isExploding && <ConfettiExplosion className="z-[10]" />} */}

      {/* Container for heading, typography and button */}
      <Image
        src={bg}
        alt="bg"
        className="absolute top-0 left-0 w-full h-[480px] object-center object-cover z-[2]"
      />

      <div className="flex flex-col gap-[22px] -600:gap-[15px] items-center text-center relative z-[3]">
        <HeadingPill content="PAAL PARTNER PROGRAM" />
        <h1 className="heading max-w-[773px] heading-anim split">
          Earn revenue and other rewards by joining the{" "}
          <span className="text-purple2">Paal Partner Program!</span>
        </h1>
        <p className="max-w-[768px] heading-para para-anim">
          Together with our partners, we’re making Paal smarter and more capable
          of delivering advanced AI experiences.  Paal Partners are{" "}
          <span className="font-medium text-white">tracked and rewarded</span>{" "}
          by sharing in paal revenue streams related to the partner’s
          contribution.
        </p>

        <Button className="bg-white text-black w-[214px] h-[48px]  -600:w-[175px] -600:h-[36px]  !p-0 hover:bg-white mt-[43px] -600:mt-[24px] -600:rounded-[6px] -600:p-[11px]">
          <a
            href="https://docs.paal.ai/collaborating/how-we-work-together"
            target="_blank"
            className="w-full h-full flex justify-center items-center text-[16px] font-medium -600:text-[14px]"
          >
            {" "}
            Become a Paal Partner
          </a>
        </Button>
      </div>

      {/* Categories for partners */}
      <div className="mt-[136px] -768:mt-[56px] relative z-[4]">
        <p className="text-white text-[24px] leading-[116.7%] font-medium -768:text-[16px] text-left">
          We’re currently seeking partners in the following categories:
        </p>

        {/* Container for cards */}
        <div className="mt-[24px] gap-[8px] flex flex-wrap justify-center">
          {partnerList.map((partner, idx) => {
            return <PartnerCard key={idx} {...partner} />;
          })}
        </div>
      </div>
    </section>
  );
}

interface CardTypes {
  img: string | StaticImageData;
  heading: string;
  para: string;
  className?: string;
}

function PartnerCard({ img, heading, para, className }: CardTypes) {
  const [showLess, setShowLess] = useState(false);

  useEffect(() => {
    const mm = gsap.matchMedia();

    // If the screen size is less than 768px set readmore to true by default
    mm.add("(max-width: 900px)", () => {
      setShowLess(true);
    });
    mm.add("(min-width: 900px)", () => {
      setShowLess(false);
    });
  }, []);

  return (
    <Card
      className={`flex flex-col justify-start items-start gap-[14px] rounded-[12px] bg-[#FFFFFF1A] relative -900:max-w-[initial] max-w-[276px] -1024:p-[18px] -600:p-[12px] w-full p-[24px] border-none ${className}`}
    >
      {/* Background Blur on the top left */}
      <Image
        src={cardBlur}
        alt="blur"
        className="absolute top-0 left-0 w-[124px] h-[124px]"
      />

      {/* Icon */}
      <Image src={img} alt="icon" />

      {/* Heading */}
      <h3 className="text-[24px] font-semibold text-purple1 -600:text-[16px]">
        {heading}
      </h3>

      {/* Paragraph */}
      <p
        className={`text-[#FFFFFFB2] leading-[137.5%] -600:text-[12px] tracking-wide font-normal partner-text ${
          showLess ? "active" : ""
        }`}
      >
        {para}
      </p>
    </Card>
  );
}
