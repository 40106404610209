"use client";

import React, { useRef, useState } from "react";
import HeadingPill from "../components/HeadingPill";
import blur from "../../public/images/about_blur.svg";
import video_overlay from "../../public/images/video_overlay.png";
import Image from "next/image";
import logo2 from "../../public/images/logo-2.png";
import AboutLineLeft from "../components/AboutLineLeft";
import AboutLineRight from "../components/AboutLineRight";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import companies_marquee from "../../public/images/companies_marquee.png";
import { ScrollTrigger } from "gsap/all";

gsap.registerPlugin(ScrollTrigger);

export default function About() {
  const [coinStats, setCoinStats] = useState<{
    cap: number | string;
    volume: number | string;
  }>({ cap: "...", volume: "..." });

  const stats: { title: any; para: string; className: string }[] = [
    {
      title: coinStats?.volume,
      para: "Total Trading Volume",
      className:
        "border-r-[1px] -968:flex-[1_1_50%] -350:flex-[1_1_100%] -350:border-r-0",
    },
    {
      title: "100K +",
      para: "AI Apps",
      className:
        "border-r-[1px] -968:flex-[1_1_50%] -350:flex-[1_1_100%] -968:border-r-[0px] -350:border-t-[1px]",
    },
    {
      title: coinStats?.cap,
      para: "Market Capitalization",
      className: "-968:flex-[1_1_100%] -968:border-t-[1px]",
    },
  ];

  async function fetchStats() {
    const fetched = await fetch(
      "https://api.coingecko.com/api/v3/coins/paal-ai"
    );
    const data = await fetched.json();
    const cap = data.market_data.fully_diluted_valuation.usd || 101414052;
    const volume = data.market_data.total_volume.usd || 20000000;

    setCoinStats({
      cap: `$${(cap - 100000000)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
      volume: `$${(volume - 100000)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
    });
  }

  const vid = useRef<HTMLVideoElement>(null!);

  useGSAP(() => {
    vid.current.muted = true;
    vid.current.play();
    fetchStats();

    const paths = document.querySelectorAll<SVGPathElement>(".about-line");

    paths.forEach((path) => {
      // Get the total length of the path
      const pathLength = path?.getTotalLength();

      // Set initial styles for the path
      gsap.set(path, {
        strokeDasharray: pathLength, // Total path length
        strokeDashoffset: -pathLength, // Initially hide the path
      });

      // Animate the stroke-dashoffset to reveal the path as the user scrolls
      gsap.to(path, {
        strokeDashoffset: 0, // Fully reveal the path
        ease: "none",
        scrollTrigger: {
          trigger: ".about-line-container",
          start: "top 100%",
          end: "70% 80%",
          scrub: 1,
        },
      });
    });

    // Animation for marquee
    const marquees = document.querySelectorAll(".companies");
    marquees.forEach((marquee, idx) => {
      gsap.to(marquee, {
        x: idx === 0 ? "-103%" : "0%",
        ease: "none",
        repeat: -1,
        duration: 15,
      });
    });
  });

  return (
    <section className="relative mt-[129px] -768:mt-[116px] -600:mt-[85px]">
      <Image
        src={blur}
        alt="blur"
        className="absolute top-[-450px] -768:top-[-250px] max-w-[600px] w-full -768:max-w-[330px] center-x  z-[1] pointer-events-none"
      />

      {/* Container for heading and paragraphs */}
      <div className="flex flex-col items-center gap-[16px] text-center -768:max-w-[450px] mx-auto px-[20px]">
        <HeadingPill content="ABout paal" />
        <h2 className="max-w-[890px] heading heading-anim split">
          Paal is a robust ecosystem leveraging advanced{" "}
          <span className="text-purple1">AI and ML</span> Technologies.
        </h2>

        <p className="max-w-[680px] heading-para text-[#FFFFFFB2]  mt-[6px] para-anim">
          Paal enables users build advanced AI applications and deploy them
          across multiple platforms, including Telegram, Discord, WhatsApp,
          Slack, iOS, VisionOS, and various web channels.{" "}
        </p>
      </div>

      {/* Blobs */}

      <div className="relative">
        <video
          ref={vid}
          width="100%"
          autoPlay
          preload="none"
          playsInline
          controls={false}
          loop
          muted={true}
          onLoad={() => {
            ScrollTrigger.refresh();
          }}
          className="inset-0 object-contain  object-center mt-[77px] -768:mt-[49px] max-w-[1000px] mx-auto  "
        >
          {/* <source src="/videos/neural_networks.webm" type="video/webm" /> */}
          <source src="/videos/neural_networks.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        {/* <div className="absolute h-full w-full video-radial-gradient mx-auto max-w-[1000px] inset-0"></div> */}
        <Image
          src={video_overlay}
          className="absolute h-full w-full video-radial-gradient mx-auto max-w-[1000px] inset-0"
          alt="overlay"
        />
      </div>

      {/* COntainer for top companies marquee and stats */}
      <div className="w-full relative mt-[124px] -600:mt-[118px]">
        <div className="max-w-[1073px] w-full mx-auto flex justify-start pl-[40px] -600:pl-[20px]">
          <h3 className="max-w-[414px] text-[24px] -768:text-[16px] -768:max-w-[278px] font-medium leading-[137.5%] mb-[50px] -600:mb-[31px] heading-anim split">
            <span className="text-[#FFFFFF80]">
              {" "}
              Paal AI Powers AI App Creation and Machine Learning Algorithms for
            </span>{" "}
            Top Web2 & Web3 Companies
          </h3>
        </div>

        {/* Container with gradient border, stats and logo */}
        <div className="max-w-[1073px] h-[400px] -968:h-[336px] w-[95%] mx-auto relative about-line-container">
          {/* Container that has linear gradient border */}
          <div className="flex absolute w-full h-[87px] -768:h-[47px] -500:h-[27px] bg-body">
            <Image
              src={logo2}
              alt="logo"
              className="absolute center-x max-w-[88px] -768:max-w-[50px] z-[3] translate-y-[-50%]"
            />

            {/* Left border */}
            {/* <AboutLineLeft className="absolute left-0 w-[50%] z-[2]" /> */}

            {/* Right border */}
            {/* <AboutLineRight className="absolute right-0 w-[50%] z-[2]" /> */}
            {/* <div className="w-[50%] h-[87px] about-card-gradient-left rounded-t-[16px] p-[2px] pb-0">
              <div className="w-full h-full rounded-t-[16px] bg-mainBg"></div>
            </div>
            <div className="w-[50%] h-[87px] about-card-gradient-right rounded-t-[16px] p-[2px] pb-0">
              <div className="w-full h-full rounded-t-[16px] bg-mainBg"></div>
            </div> */}
          </div>

          {/* Container with normal grey border */}
          <div className="border-[2px] border-[#FFFFFF33] rounded-[16px] h-full w-full flex items-end justify-center relative overflow-hidden">
            {/* Container for maruqee */}
            <div className="absolute center-y  -968:top-[40%] -600:top-[50%] -968:w-[1500px] -600:w-[1200px]  w-[1700px] z-[7] overflow-hidden h-[100px]">
              {/* Companies */}
              <Image
                className="w-[1700px] inset-0 absolute companies will-change-transform object-center -968:w-[1500px] -600:w-[1200px] "
                src={companies_marquee}
                alt="companies"
              />
              <Image
                className="w-[1700px] inset-0 absolute translate-x-[103%] companies will-change-transform object-center  -968:w-[1500px] -600:w-[1200px]"
                src={companies_marquee}
                alt="companies"
              />

              {/* Marquee blur */}
              {/* <Image
            src={marquee_blur}
            alt="blur"
            className="absolute inset-0 z-[12] w-full scale-x-[1.1]  -1024:scale-y-[1.5] -768:h-[50px]"
          /> */}
            </div>
          </div>

          {/* Container for stats */}
          <div className="flex rounded-[12px] border-[#FFFFFF33] border-[1px] w-fit mx-auto justify-center translate-y-[-50%]  -600:w-[90%] bg-mainBg -968:flex-wrap ">
            {stats.map((stat, idx) => {
              return (
                <div
                  key={idx}
                  className={`flex flex-col justify-center items-center gap-[12px] w-[284px] -968:w-[154px] py-[40px] -768:py-[20px] bg-[#FFFFFF0A] border-[#FFFFFF33] ${stat.className}`}
                >
                  <h3 className="text-purple1 text-[24px] -768:text-[20px] font-semibold">
                    {stat.title}
                  </h3>
                  <p className="text-[20px] text-[#F3F3F499] leading-[110%] -768:text-[14px] font-medium">
                    {stat.para}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
}
