"use client";

import React, { useEffect, useState } from "react";
import HeadingPill from "../components/HeadingPill";
import { useGSAP } from "@gsap/react";
import { Card } from "@/components/ui/card";
import ProductsLine from "../components/ProductsLine";
import { MotionPathPlugin, ScrollTrigger } from "gsap/all";
import gsap from "gsap";
import Button from "../components/Button";
import ProductTick from "../components/ProductTick";
import paal_bot from "../../public/images/paal_bot.png";
import paal_bot_mob from "../../public/images/paal_bot_mob.png";
import agents from "../../public/images/agents.png";
import agents_mob from "../../public/images/agents_mob.png";
import trading from "../../public/images/trading.png";
import trading_mob from "../../public/images/trading_mob.png";
import memes from "../../public/images/memes.png";
import memes_mob from "../../public/images/memes_mob.png";
import launch_mob from "../../public/images/launch_mob.png";
import black_blur from "../../public/images/black_blur.svg";
import arrow from "../../public/images/arrow.svg";
import Image, { StaticImageData } from "next/image";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import arrowDown from "../../public/images/product_arrow_down.svg";

gsap.registerPlugin(ScrollTrigger, MotionPathPlugin);

export default function Products() {
  const productList: CardTypes[] = [
    {
      heading: "Paal Bot",
      subHeading:
        "Super powerful AI Bot Assistance for communities, groups or individuals to integrate anywhere",
      benefitList: [
        "AI Assistance for any topic with real-time internet data",
        "AI Assistance for crypto research and real-time metrics",
        "Customizable to any data/content/topic",
        "White Label, Clones for brands, projects, communities or businesses",
      ],
      btnName: "Get bot",
      btnLink: "https://app.paal.ai/",
      bgColor: "#121F17",
      headingColor: "#A5FFA2",
      imgSrc: paal_bot,
      imgSrcMob: paal_bot_mob,
    },
    {
      heading: "Enterprise Agents",
      subHeading:
        "Create, deploy and manage AI-driven assistants for specific tasks and workflows",
      benefitList: [
        "Automate processes with REST API, Knowledge Base or IoT agents",
        "Integrate seamlessly into external systems and platorms",
        "Use drag-and-drop workflows with the intuitive Agent Flow Builder",
        "Test agents in real-time using the playground",
        "Secure integrations with API keys for enhanced functionality",
      ],
      btnName: "agents.paal.ai",
      btnLink: "https://agents.paal.ai/auth/login",
      bgColor: "#301C17",
      headingColor: "#FFBEA2",
      imgSrc: agents,
      imgSrcMob: agents_mob,
    },
    {
      heading: "Autonomous Trading Agent",
      subHeading: "Provides both automated and manual trading capabilities",
      benefitList: [
        "Execute trades based on predefined rules",
        "Analyze market trends, select tokens and execute buy or sell orders in real-time",
        "Create, modify and monitor AI models that generate trading signals tailored to your strategies",
        "Set parameters such as trade size, take profit, stop loss and maximum slippage",
        "Navigate through a seamless platform with features like night mode, community engagement links and FAQs",
      ],
      btnName: "Start trading",
      btnLink: "https://x.paal.ai",
      bgColor: "#181F3A",
      headingColor: "#A2C3FF",
      imgSrc: trading,
      imgSrcMob: trading_mob,
    },
    {
      heading: "MyMemes",
      subHeading:
        "Offering some of the best tools for meme creation, MyMemes is Designed for creatives to bring their memes to life. Create movies, 3D characters, animations, gifs and images.  ",
      benefitList: [
        "Deploy a meme agent to continuously distribute content across various platforms, including live streaming, X posts, YouTube uploads, and more. ",
        "Browse the latest and most popular memes created by the community",
        "Discover trending memes and stay updated with viral ones",
        "Manage all your created memes super easily",
        "Analyze performance metrics for memes including views, likes and shares",
      ],
      btnName: "mymemes.ai",
      btnLink: "http://mymemes.ai/",
      bgColor: "#2C2725",
      headingColor: "#F9FFA2",
      imgSrc: memes,
      imgSrcMob: memes_mob,
    },
    // {
    //   heading: "Launch.Paal",
    //   subHeading:
    //     "Easily tokenize any AI application with Launch.Paal. Simplified creation and deployment",
    //   benefitList: [
    //     "Create tokens for AI-powered applications with ease",
    //     "Customize token details like name, ticker and description",
    //     "Categorize your project as a Meme, Agent or Bot",
    //     "Add project links (website, X, Telegram etc) for increases visibility",
    //   ],
    //   btnName: "launch.paal",
    //   btnLink: "http://launch.paal.ai/",
    //   bgColor: "#262F3B",
    //   headingColor: "#A2EBFF",
    //   imgSrc: launch,
    //   imgSrcMob: launchMob
    // },
  ];

  useGSAP(() => {
    const mm = gsap.matchMedia();

    mm.add("(min-width: 1024px)", () => {
      gsap.to(".motion-circle", {
        scrollTrigger: {
          trigger: ".motion-line.controller", // The container that scrolls
          start: "top 65%", // Animation starts when the container starts
          end: "bottom 65%", // Animation ends when the container ends
          scrub: true,
        },
        ease: "none",
        motionPath: {
          path: ".motion-line.drawn", // Reference to the SVG path
          align: ".motion-line.drawn", // Align the circle along the path
          alignOrigin: [0.5, 0.5], // Center alignment
          autoRotate: true,
          autofocus: true,
        },
      });
      const path = document.querySelector<SVGPathElement>(".motion-line.drawn");

      // Get the total length of the path
      const pathLength = path?.getTotalLength();

      // Set initial styles for the path
      gsap.set(path, {
        strokeDasharray: pathLength, // Total path length
        strokeDashoffset: pathLength, // Initially hide the path
      });

      // Animate the stroke-dashoffset to reveal the path as the user scrolls
      gsap.to(path, {
        strokeDashoffset: 0, // Fully reveal the path
        strokeDasharray: pathLength,
        ease: "none",
        scrollTrigger: {
          trigger: ".motion-line.controller", // The scrollable container
          start: "top 65%", // Start the animation when the container starts
          end: "bottom 65%", // End the animation at the bottom of the container
          scrub: true, // Sync animation with scroll
        },
      });
    });
  });

  const numberList = [
    { number: 1, className: "bg-[#A5FFA2]" },
    { number: 2, className: "bg-[#FFBEA2]" },
    { number: 3, className: "bg-[#A2C3FF]" },
    { number: 4, className: "bg-[#F9FFA2]" },
    { number: 5, className: "bg-[#A2EBFF]" },
  ];

  return (
    <section id="products" className="mt-[300px] -1024:mt-[222px] px-[15px]">
      {/* Container for heading pill and sub heading */}
      <div className="flex flex-col gap-[16px] mx-auto items-center justify-center relative">
        <HeadingPill content="Products" />
        <h2 className="max-w-[745px] text-[#FFFFFF] font-semibold text-[32px] bg-body -600:text-[20px] -600:max-w-[345px] leading-[137.5%] text-center relative z-[5] heading-anim split">
          PAAL is a <span className="text-purple1">Powerful Ecosystem</span>{" "}
          revolutionizing digital experiences, combining advanced bots,
          intelligent agents, immersive visual content, and innovative utilities
          to enhance user knowledge, foster creativity, and empower communities
          across diverse industries.
        </h2>

        <Image
          src={black_blur}
          alt="blur"
          className="absolute center-x z-[4] bottom-[-100px]"
        />
      </div>

      {/* Container for products cards and product line animation */}
      <div className="flex flex-col -1024:flex-row -1024:gap-[20px] -1024:flex-wrap gap-[70px] -1250:gap-[40px] items-center -1024:items-start relative w-full products-container mt-[280px] -1024:mt-[140px] -768:mt-[100px] -600:mt-[43px] ">
        {/* Line that has opacity */}
        <ProductsLine
          stroke="#FFFFFF4D"
          className="absolute top-[-250px] z-[2] w-[95%] scale-y-[1.03] max-w-[1245px] -1250:h-[109%] -1024:hidden pointer-events-none"
          pathClass=" controller opacity-[0.7] absolute inset-0 h-full"
        />

        {/* White line being drawn over the above line */}
        <ProductsLine
          className="absolute top-[-250px] z-[2] w-[95%] scale-y-[1.03] max-w-[1245px] -1250:h-[109%] -1024:hidden pointer-events-none"
          stroke="white"
          pathClass="drawn absolute inset-0 h-full"
        />
        {/* White bubble following the white line */}
        <div className="bg-[white] absolute h-[15px] w-[15px] z-[3] rounded-full motion-circle -1024:hidden"></div>

        {productList.map((product, idx) => {
          return <ProductCard key={idx} {...product} idx={idx} />;
        })}

        {/* Bubble list that shows on the left */}
        {/* <div className="flex flex-col rounded-[12px] bg-[#FFFFFF1A] px-[6px] absolute left-0 top-0 w-[56px] text-[20px] text-black product-number-list">
          {numberList.map((number) => {
            return (
              <div
                data-color={number.className}
                className={`bg-transparent h-[44px] w-[45px] rounded-[8px] flex justify-center items-center font-medium product-number`}
              >
                {number.number}
              </div>
            );
          })}
        </div> */}
      </div>
    </section>
  );
}

interface CardTypes {
  heading: string;
  subHeading: string;
  benefitList: string[];
  btnName: string;
  bgColor: string;
  headingColor: string;
  btnLink: string;
  imgSrc: string | StaticImageData;
  imgSrcMob: string | StaticImageData;
  idx?: number;
}

function ProductCard({
  heading,
  subHeading,
  benefitList,
  btnName,
  bgColor,
  headingColor,
  btnLink,
  imgSrc,
  idx,
  imgSrcMob,
}: CardTypes) {
  const [showMore, setShowMore] = useState(true);
  return (
    <Card
      style={{
        backgroundColor: `${bgColor}`,
      }}
      className={`rounded-[32px]  -1024:rounded-[16px] relative z-[7] h-[571px] border-0 max-w-[1120px] w-[90%] flex items-center !p-0 overflow-hidden -1024:flex-col -1024:items-start -1024:gap-[16px] -1024:h-fit  -1024:flex-[0_0_47.9%] -550:flex-[1_1_100%] `}
    >
      {/* Container for typography */}
      <div className="flex flex-col gap-[19px] -600:gap-[13px] -1250:gap-[15px] pl-[48px] pr-[35px] -1024:pt-[30px] -1024:p-[20px] -600:p-[16px] -1024:order-2">
        {/* Heading */}
        <h3
          style={{ color: `${headingColor}` }}
          className={`text-[30px] -1250:text-[24px] -600:text-[20px] font-semibold para-anim`}
        >
          {heading}
        </h3>

        {/* Paragraph */}
        <p className="text-[20px] -1250:text-[18px] -600:text-[16px] leading-[140%] text-white font-medium para-anim">
          {subHeading}
        </p>

        {/* List shown on desktop */}
        <ul className="flex flex-col items-start gap-[16px] -1250:gap-[11px] text-[#FFFFFFB2] max-w-[495px] -1250:text-[14px] para-anim -1024:hidden">
          {benefitList.map((item, idx) => {
            return (
              <li
                key={idx}
                className="flex gap-[11px] -1250:gap-[8px] items-start -600:text-[14px]"
              >
                <ProductTick
                  className="min-w-[24px] max-w-[24px] -1250:min-w-[18px] -1250:max-w-[18px] -600:!max-w-[16px] -600:min-w-[16px]"
                  fill={headingColor}
                />
                <p>{item}</p>
              </li>
            );
          })}
        </ul>

        {/* Collapsible list on mobile */}
        <Accordion type="single" className="hidden -1024:block" collapsible>
          {/* List of benefits */}
          <AccordionItem value={"item-1"}>
            <AccordionContent>
              <ul className="flex flex-col items-start gap-[16px] -1250:gap-[11px] text-[#FFFFFFB2] max-w-[495px] -1250:text-[14px] para-anim">
                {benefitList.map((item, idx) => {
                  return (
                    <li
                      key={idx}
                      className="flex gap-[11px] -1250:gap-[8px] items-start -600:text-[14px]"
                    >
                      <ProductTick
                        className="min-w-[24px] max-w-[24px] -1250:min-w-[18px] -1250:max-w-[18px] -600:!max-w-[16px] -600:min-w-[16px]"
                        fill={headingColor}
                      />
                      <p>{item}</p>
                    </li>
                  );
                })}
              </ul>
            </AccordionContent>

            {/* Show more or less for mobile */}
            <AccordionTrigger
              onClick={() => {
                setShowMore(!showMore);
              }}
              className="  hidden -1024:block !py-0"
            >
              <div className=" gap-[3px] items-center flex">
                <p className="-600:text-[14px] text-[#FFFFFF99] font-medium">
                  {showMore ? " Show more" : "Show Less"}
                </p>
                <Image
                  src={arrowDown}
                  alt="arrow"
                  className={`${
                    showMore ? "top-[2px]" : "rotate-[180deg] bottom-[2px]"
                  } duration-200 ease-in-out object-center object-cover relative`}
                />
              </div>
            </AccordionTrigger>
          </AccordionItem>
        </Accordion>

        {/* Button */}
        <Button
          className="w-[233px] h-[48px] -600:w-[163px] -600:h-[40px] -600:text-[14px] text-white btn-arrow !py-0"
          style={{
            backgroundColor: `${bgColor}`,
          }}
          hasBorder={true}
        >
          <a
            href={btnLink}
            target="_blank"
            className="w-full h-full flex gap-[10px] justify-center items-center"
          >
            {btnName}
            <Image src={arrow} alt="arrow" />
          </a>
        </Button>
      </div>

      {/* Container for image */}
      <div className="relative h-full w-full max-w-[517px] -1024:h-[174px] ">
        <p className="text-[#FFFFFF4D] font-medium text-[40px] absolute left-[-44px] top-[10px] -1024:hidden">
          {idx !== undefined ? idx + 1 : ""}
        </p>
        <Image
          onLoad={() => {
            ScrollTrigger.refresh();
          }}
          src={imgSrc}
          className="object-center object-cover  w-full h-full -1024:object-top -1024:object-cover -1024:hidden"
          alt="pic"
        />
        <Image
          onLoad={() => {
            ScrollTrigger.refresh();
          }}
          src={imgSrcMob}
          className="object-center object-cover  w-full h-full -1024:object-top -1024:object-cover -1024:order-1 hidden -1024:block"
          alt="pic"
        />
      </div>

      {/* Number on mobile */}
      <p className="text-[#FFFFFF4D] font-medium text-[40px] -600:text-[20px] absolute right-[16px] top-[10px] hidden -1024:block ">
        {idx !== undefined ? idx + 1 : ""}
      </p>
    </Card>
  );
}
