"use client";

import React, { useEffect } from "react";
import Image from "next/image";
import Button from "../components/Button";
import Lenis from "lenis";
import ibm from "../../public/images/ibm.svg";
import google_cloud from "../../public/images/google_cloud.png";
import coin_gecko from "../../public/images/coin_gecko.png";
import bot_icon from "../../public/images/bot_icon.svg";
import cat_avatar from "../../public/images/cat_avatar.png";
import bot_avatar from "../../public/images/bot_avatar.png";
import SplitType from "split-type";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import hero_bg from "../../public/images/hero_bg.png";

export default function Hero() {
  const supportedLogos = [
    { name: ibm, className: "w-[56px] -600:w-[42px]" },
    { name: google_cloud, className: "max-w-[157px] -600:w-[118px]" },
    { name: coin_gecko, className: "max-w-[120px] -600:w-[90px]" },
  ];

  useEffect(() => {
    // Initialize Lenis
    const lenis = new Lenis({
      autoRaf: true,
    });

    const allFrames = document.querySelectorAll("iframe");

    lenis.on("scroll", ({ velocity }) => {
      // console.log("is scrolling");

      if (Math.abs(velocity) > 0.2) {
        allFrames.forEach((frame) => {
          frame.classList.add("active");
        });
      } else if (Math.abs(velocity) < 0.2) {
        allFrames.forEach((frame) => {
          frame.classList.remove("active");
        });
      }
    });
  }, []);

  useGSAP(() => {
    const messages = new SplitType(".chat-message", { types: "words,chars" });

    const allMessages = document.querySelectorAll(".chat-message");

    function animateMessage(element: Element) {
      return new Promise((resolve) => {
        gsap
          .timeline()
          .to(element, {
            y: 0,
            opacity: 1,
            duration: 0.4,
            ease: "power2.out",
          })
          .from(
            element.querySelector("img"),
            {
              scale: 0,
            },
            0
          )
          .from(element.querySelectorAll(".char"), {
            opacity: 0,
            duration: 0.001,
            stagger: 0.015,
            onComplete: resolve,
          });
      });
    }

    async function animateSequentially() {
      for (let i = 0; i < allMessages.length; i++) {
        await animateMessage(allMessages[i]);
      }
    }

    animateSequentially();

    function AnimateWidth(element: string) {
      gsap.to(element, {
        position: "relative",
        onComplete: () => {
          const container =
            document.querySelector<HTMLElement>(".word-container");
          let childElements = container?.querySelectorAll("p");
          let targetEl: HTMLElement;
          childElements?.forEach((el) => {
            const style = window.getComputedStyle(el);
            const positionValue = style.position;

            if (positionValue === "relative") {
              console.log(el);
              targetEl = el as HTMLElement;
              console.log(targetEl?.offsetWidth);
              gsap.to(".word-container", {
                duration: 1,
                width: targetEl?.offsetWidth, // Automatically adjusts the width based on the content
                // ease: "power2.inOut",
              });
            }
          });
        },
      });
    }

    // Animation for the text in the hero
    gsap
      .timeline({ repeat: -1 })
      .to(".word-1", {
        delay: 1,
        y: -70,
        position: "absolute",
        opacity: 0,
        ease: "power2.inOut",
      })
      .to(
        ".word-2",
        {
          y: 0,
          position: "relative",
          opacity: 1,
          ease: "power2.inOut",
        },
        "<"
      )
      .to(".word-2", {
        delay: 1,
        y: -70,
        position: "absolute",
        opacity: 0,
        ease: "power2.inOut",
      })
      .to(
        ".word-3",
        {
          y: 0,
          opacity: 1,
          ease: "power2.inOut",
          position: "relative",
        },
        "<"
      )
      .to(".word-3", {
        delay: 1,
        y: -70,
        position: "absolute",
        opacity: 0,
        ease: "power2.inOut",
      })
      .to(
        ".word-4",
        {
          y: 0,
          position: "relative",
          opacity: 1,
          ease: "power2.inOut",
        },
        "<"
      );
  });

  return (
    <section className="px-[120px] -1250:px-[40px] pt-[140px] -600:pt-[73px]  -1100:px-[100px] -768:px-[40px] -500:px-[20px] relative">
      {/* Container for Bg */}
      <Image
        src={hero_bg}
        alt="bg"
        className="absolute inset-0 top-[-100px] h-[800px] object-cover object-left"
      />

      {/* Container for main hero i.e typography and ai conversation */}
      <div className="flex justify-between items-center -1100:flex-col-reverse -1100:gap-[80px] -1100:items-start relative max-w-[1700px] mx-auto">
        {/* Container for typography and buttons */}
        <div className="max-w-[650px] flex flex-col gap-[35px] -600:gap-[26px] items-start">
          {/* Heading */}
          <h1 className="text-[64px] -968:text-[42px]  leading-[100%] -968:leading-[120%] font-semibold hero-gradient-text -500:max-w-[340px] ">
            <span className=" relative inline-block overflow-hidden translate-y-[10px] word-container hero-span">
              <p className="relative translate-y-[0px] top-[0px] left-0 word-1 text-[#B798FF]">
                Create
              </p>
              <p className="absolute translate-y-[70px] opacity-0 top-[0px] left-0 word-2 text-[#D969FF]">
                Tokenize
              </p>
              <p className="absolute translate-y-[70px] opacity-0 top-[0px] left-0 word-3 text-[#E46AB7]">
                Integrate
              </p>
              <p className="absolute translate-y-[70px] opacity-0 top-[0px] left-0 word-4 text-[#B798FF] ">
                Create
              </p>
            </span>{" "}
            Advanced <br /> <span className="text-purple1">AI</span>{" "}
            Applications using Paal.
          </h1>

          {/* Paragraph */}
          <p className="text-[28px] -968:text-[20px] -600:text-[16px]">
            There are no limits.
          </p>

          {/* Container for buttons */}
          <div className="flex gap-[12px] items-center text-[18px] -600:text-[14px] flex-wrap">
            <Button
            
            className="bg-[#7547DD] w-[193px] -600:w-[144px] -600:h-[40px]">
              <a
                href="https://order.paal.ai/"
                className="flex justify-center items-center h-full w-full gap-[10px] "
              >
                {" "}
                <Image src={bot_icon} alt="bot" />
                <p>Get a Paal Bot</p>
              </a>
            </Button>
            <Button

            
              hasBorder={true}
              className=" w-[153px] -600:w-[114px] -600:h-[40px] bg-body"
            >
              <a
                href="https://app.uniswap.org/swap?&chain=mainnet&use=v2&outputCurrency=0x14fee680690900ba0cccfc76ad70fd1b95d10e16"
                className="h-full w-full"
              >
                {" "}
                Buy $Paal
              </a>
            </Button>
          </div>
        </div>

        {/* Container for animating text messages */}
        <div className="flex flex-col font-consolas w-[90%] max-w-[433px] gap-[20px] -600:gap-[15px] -1100:self-end -600:self-start">
          {/* First message */}
          <div className="self-start chat-message">
            <Image src={cat_avatar} className="chat-avatar" alt="" />
            <div className="cat-chat-gradient chat-bubble rounded-bl-none  w-[255px] -600:w-[197px]">
              REQUESTING_SERVICE: market_analysis_package_001
            </div>
          </div>

          {/* Second message */}
          <div className="self-end chat-message">
            <div className="bot-chat-gradient chat-bubble rounded-br-none  w-[250px] -600:w-[193px]">
              <p>
                {" "}
                SERVICE_DETAILS: <br />
                {"{"}price: 100, <br /> duration: 24h,
                <br /> access_level: full{"}"}
              </p>
            </div>
            <Image src={bot_avatar} className="chat-avatar" alt="" />
          </div>

          {/* Third message */}
          <div className=" self-start chat-message">
            <Image src={cat_avatar} className="chat-avatar" alt="" />
            <div className="cat-chat-gradient chat-bubble rounded-bl-none  w-[288px]  -600:w-[222px]">
              INITIATING_PAYMENT: 100_credits
            </div>
          </div>

          {/* Fouth message */}
          <div className="self-end chat-message">
            <div className="bot-chat-gradient chat-bubble rounded-br-none  w-[250px] -600:w-[193px]">
              <p>PAYMENT_RECEIVED: TRUE</p>
            </div>
            <Image src={bot_avatar} className="chat-avatar" alt="" />
          </div>

          {/* Fifth message */}
          <div className="self-end chat-message">
            <div className="bot-chat-gradient chat-bubble min-w-[250px]">
              <p>
                STARTING_ANALYSIS: <br />
                market_data_feed_active
              </p>
            </div>
            <Image src={bot_avatar} alt="" className="chat-avatar" />
          </div>
        </div>
      </div>

      {/* Container for support and iframe youtube */}
      <div className="mt-[159px] -600:mt-[124px] flex flex-col items-start max-w-[1700px] mx-auto">
        <h2 className="text-[14px] tracking-widest -600:text-[12px]">SUPPORTED BY</h2>

        {/* COntainer for company logos */}
        <div className="flex items-center justify-start gap-[71px] -768:gap-[42px] mt-[21px] flex-wrap">
          {supportedLogos.map((logo, idx) => {
            return (
              <Image
                key={idx}
                src={logo.name}
                className={`${logo.className}`}
                alt=""
              />
            );
          })}
        </div>

        {/* Container for iframe */}
        <div className="rounded-[12px] w-full video-gradient p-[2px] h-[672px] -1024:h-[400px] -600:h-[300px] -600:max-w-[400px] mx-auto -400:h-[262px] mt-[48px] -600:mt-[24px] relative z-[2] overflow-hidden">
          <div className="bg-body h-full w-full rounded-[12px] overflow-hidden">
            <iframe
              className=" w-full h-full rounded-[12px]"
              src="https://www.youtube.com/embed/RHtumfBr_wY?si=YUkm791OV0qTpypG&controls=0"
            ></iframe>
          </div>
        </div>
      </div>
    </section>
  );
}
