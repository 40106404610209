"use client";

import Image from "next/image";
import React, { useEffect, useRef } from "react";
import logo from "../../public/images/logo.png";
import arrow from "../../public/images/arrow.svg";
import burger_open from "../../public/images/burger_open.svg";
import burger_close from "../../public/images/burger_close.svg";
import Button from "./Button";
import Link from "next/link";
import Lenis from "lenis";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import { ScrollToPlugin, ScrollTrigger, CustomEase } from "gsap/all";
import { usePathname, useRouter } from "next/navigation";
import SplitType from "split-type";

gsap.registerPlugin(ScrollToPlugin);
gsap.registerPlugin(ScrollTrigger);

export default function Navbar() {
  const navList: { name: string; link: string; id?: string }[] = [
    { name: "Products", link: "/#products", id: "#products" },
    { name: "Services", link: "/#services", id: "#services" },
    { name: "Token", link: "/" },
    { name: "Integration", link: "/" },
    { name: "Partners", link: "/#partners", id: "#partners" },
    { name: "Roadmap", link: "/roadmap" },
    { name: "Blog", link: "/blog" },
    { name: "Videos", link: "/videos" },
  ];
  const pathname = usePathname();
  const router = useRouter();

  useEffect(() => {
    const lenis = new Lenis({
      duration: 0.8,
    });
    // Synchronize Lenis scrolling with GSAP's ScrollTrigger plugin
    lenis.on("scroll", ScrollTrigger.update);

    // Add Lenis's requestAnimationFrame (raf) method to GSAP's ticker
    // This ensures Lenis's smooth scroll animation updates on each GSAP tick
    gsap.ticker.add((time) => {
      lenis.raf(time * 1000); // Convert time from seconds to milliseconds
    });

    // Disable lag smoothing in GSAP to prevent any delay in scroll animations
    gsap.ticker.lagSmoothing(0);

    // Ensure ScrollTrigger uses Lenis's scroll position
    ScrollTrigger.scrollerProxy(document.body, {
      scrollTop(value) {
        return value !== undefined ? lenis.scrollTo(value) : lenis.scroll;
      },
      getBoundingClientRect() {
        return {
          top: 0,
          left: 0,
          width: window.innerWidth,
          height: window.innerHeight,
        };
      },
      pinType: document.body.style.transform ? "transform" : "fixed",
    });

    // Refresh ScrollTrigger after setting up Lenis
    ScrollTrigger.addEventListener("refresh", () => {
      setTimeout(() => ScrollTrigger.update(), 0); // Ensure ScrollTrigger syncs properly
    });
    ScrollTrigger.refresh();

    // When you scroll a bit, the background color shows
    window.addEventListener("scroll", () => {
      const navBar = document.querySelector(".nav-desktop");
      const navMob = document.querySelector(".nav-mobile");
      if (window.scrollY > 150) {
        navBar?.classList.add("active");
        navMob?.classList.add("active");
      } else {
        navBar?.classList.remove("active");
        navMob?.classList.remove("active");
      }
    });

    return () => {
      // Clean up Lenis and ScrollTrigger
      lenis.destroy();
    };
  }, []);

  const myTimeline = useRef<GSAPTimeline>();

  useGSAP(() => {
    // Check if there is a hash in the URL
    const hash = window.location.hash;
    if (hash) {
      const targetId = hash.substring(1); // Remove the "#" from the hash
      const targetElement = document.getElementById(targetId);
      console.log(targetId);
      if (targetElement) {
        // Smooth scroll to the element
        gsap.to(window, {
          duration: 1.5,
          scrollTo: {
            y: `#${targetId}`,
            offsetY: 100,
          },
          ease: "power2.inOut",
        });
      }
    }

    myTimeline.current = gsap
      .timeline({ paused: true })
      .to(".nav-drawer", {
        x: "0%",
        ease: "power3.inOut",
        duration: 1,
      })
      .to(
        ".burger-open",
        {
          display: "none",
        },
        0
      )
      .from(
        ".nav-mob-item",
        {
          y: 20,
          opacity: 0,
          stagger: 0.04,
        },
        0.5
      );

    // Animating the text in
    const split = new SplitType(".split", { types: "words,chars" });
    const allHeadings = document.querySelectorAll(".heading-anim");
    const allParas = document.querySelectorAll(".para-anim");
    const allParaScrubs = document.querySelectorAll(".para-scrub");

    const myEase =
      "M0,0 C0.077,0.345 0.076,0.486 0.113,0.641 0.127,0.707 0.165,0.817 0.203,0.855 0.222,0.874 0.263,0.909 0.286,0.922 0.336,0.951 0.39,0.967 0.463,0.976 0.522,0.983 0.593,1 0.684,1 0.77,1.002 0.873,1 1,1 ";

    allHeadings.forEach((heading, idx) => {
      gsap.from(heading.querySelectorAll(".char"), {
        y: 100,
        // filter: "blur(8px)",
        ease: CustomEase.create("custom", myEase),
        duration: 2,
        opacity: 0,

        scrollTrigger: {
          trigger: heading.classList.contains("hero") ? "body" : heading,
          start: "top 80%",
          end: "bottom 80%",
          // markers: true,
        },
      });
    });

    allParas.forEach((para) => {
      gsap.from(para, {
        y: 100,
        opacity: 0,
        ease: CustomEase.create("custom", myEase),
        duration: 2,
        scrollTrigger: {
          trigger: para,
          start: "top 95%",
          end: "bottom 95%",
          // markers: true,
        },
      });
    });
  });

  function openNav() {
    myTimeline.current?.play();
  }

  function closeNav() {
    myTimeline.current?.reverse();
  }

  function ScrollToSection(e: React.MouseEvent) {
    const target = e.currentTarget as HTMLElement;

    if (!target.dataset.id) return;

    if (pathname !== "/") {
      router.push(`/${target.dataset.id}`);
      return;
    }

    e.preventDefault();
    gsap.to(window, {
      duration: 1.5,
      scrollTo: {
        y: `${target.dataset.id}`,
        offsetY: 100,
      },
      ease: "power2.inOut",
    });
  }
  return (
    <>
      {/* Nav for desktop */}
      <nav className="nav-desktop sticky top-[0px] mx-auto w-full flex justify-between items-center py-[13px] px-[32px] -1250:px-[10px] bg-transparent z-[100] -1024:hidden">
        {/* Logo */}

        <Link href="/">
          <Image
            src={logo}
            alt="logo"
            className="min-w-[120px] max-w-[168px]"
          />
        </Link>

        {/* Container for list items */}
        <ul className="flex items-center justify-center gap-[20px] -1100:gap-[15px] font-medium">
          {navList.map((link, idx) => {
            return (
              <Link
                onClick={ScrollToSection}
                className="nav-link w-fit relative -1250:text-[14px]"
                key={idx}
                href={link.link}
                data-id={link.id}
              >
                {link.name}
              </Link>
            );
          })}
        </ul>

        {/* Container for buttons */}
        <div className="flex gap-[12px] items-center">
          <Button
            hasBorder={true}
            className="w-[153px] -1100:w-[130px] h-[40px] !py-0 bg-body nav-btn"
          >
            <a
              href="https://etherscan.io/token/0x14feE680690900BA0ccCfC76AD70Fd1b95D10e16"
              className="w-full h-full flex gap-[10px] justify-center items-center btn-arrow"
            >
              <p>Etherscan</p>
              <Image src={arrow} alt="arrow" />
            </a>
          </Button>
          <Button
            hasBorder={true}
            className="w-[136px] -1100:w-[120px] h-[40px] !py-0 bg-body nav-btn"
          >
            <a
              href="https://rewards.paal.ai/"
              className="w-full h-full flex justify-center items-center"
            >
              Stake $Paal
            </a>
          </Button>
        </div>
      </nav>

      {/* Nav for mobile */}
      <nav className="nav-mobile hidden -1024:flex justify-between items-center px-[20px] pt-[22px] py-[20px] sticky top-0 z-[70]">
        <Link href="/">
          <Image src={logo} alt="logo" className="max-w-[126px]" />
        </Link>

        {/* Hamburger trigger */}
        <button onClick={openNav}>
          <Image
            src={burger_open}
            alt="burger"
            className="scale-[1.5] -600:scale-[1.1] burger-open"
          />
        </button>
      </nav>

      {/* Container for content */}
      <div className="fixed flex justify-end items-center inset-0 h-full w-full bg-[#000000D9] pr-[100px] -600:pr-[45px] nav-drawer translate-x-[100%] z-[100]">
        {/* Hamburger Close trigger */}
        <button onClick={closeNav}>
          <Image
            src={burger_close}
            alt="cross"
            className="absolute right-[50px] top-[50px] -600:right-[21px] -600:top-[26px] scale-[1.5] -600:scale-[1.1]"
          />
        </button>

        {/* Container for list items */}
        <ul className="flex flex-col items-center justify-center gap-[9px] font-medium">
          {navList.map((link, idx) => {
            return (
              <Link
                onClick={(e) => {
                  ScrollToSection(e);
                  closeNav();
                }}
                className="bg-[#E5D9EF59] action-bubble rounded-[8px] py-[14px] w-[173px] text-center relative nav-mob-item"
                key={idx}
                href={link.link}
                data-id={link.id}
              >
                {link.name}
              </Link>
            );
          })}
          <Button
            hasBorder={true}
            isMob={true}
            outerClass="nav-mob-item"
            className="w-[173px] py-[14px] bg-body nav-btn"
          >
            <a
              target="_blank"
              href="https://etherscan.io/token/0x14feE680690900BA0ccCfC76AD70Fd1b95D10e16"
              className="w-full h-full flex gap-[10px] justify-center items-center btn-arrow"
            >
              <p>Etherscan</p>
              <Image src={arrow} alt="arrow" />
            </a>
          </Button>
          <Button
            hasBorder={true}
            isMob={true}
            outerClass="nav-mob-item"
            className="w-[173px] py-[14px] bg-body nav-btn"
          >
            <a
              target="_blank"
              href="https://rewards.paal.ai/"
              className="w-full h-full"
            >
              Stake $Paal
            </a>
          </Button>
        </ul>
      </div>
    </>
  );
}
