import React from "react";

export default function ProductsLine({
  className,
  stroke = "white",
  pathClass,
}: {
  className?: string;
  stroke: string;
  pathClass?: string;
}) {
  return (
    <>
      {" "}
      <svg
        preserveAspectRatio="none"
        viewBox="0 0 1250 3032"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={`${className}  -1024:hidden -1024:invisible`}
      >
        <path
          d="M618.582 2.49609V153.793C618.582 180.303 597.091 201.793 570.582 201.793L50.083 201.793C23.5733 201.793 2.08295 223.284 2.08295 249.793L2.08296 552.36C2.08296 578.87 23.5733 600.36 50.083 600.36H899.301H1199.84C1226.35 600.36 1247.84 621.851 1247.84 648.36V1185.19C1247.84 1211.7 1226.35 1233.19 1199.84 1233.19H50.0829C23.5732 1233.19 2.08296 1254.68 2.08296 1281.19V1841.17C2.08296 1867.67 23.5733 1889.17 50.083 1889.17H1199.84C1226.35 1889.17 1247.84 1910.66 1247.84 1937.17V2468.92C1247.84 2495.9 1225.61 2517.58 1198.64 2516.91L663.866 2503.59C636.897 2502.91 614.671 2524.59 614.671 2551.57V3029.15"
          stroke={stroke}
          className={`motion-line ${pathClass}`}
          strokeWidth="4"
          strokeLinecap="round"
        />
      </svg>
    </>
  );
}

// Code for svg line with launch product
{
  /* <svg
viewBox="0 0 1250 3695"
preserveAspectRatio="none"
fill="none"
xmlns="http://www.w3.org/2000/svg"
className={`${className}  -1024:hidden -1024:invisible`}
>
<path
  d="M618.582 2.49607V153.793C618.582 180.303 597.091 201.793 570.582 201.793L50.083 201.793C23.5733 201.793 2.08295 223.284 2.08295 249.793L2.08296 552.36C2.08296 578.87 23.5733 600.36 50.083 600.36H899.301H1199.84C1226.35 600.36 1247.84 621.851 1247.84 648.36V1185.19C1247.84 1211.7 1226.35 1233.19 1199.84 1233.19H50.0829C23.5732 1233.19 2.08296 1254.68 2.08296 1281.19V1841.17C2.08296 1867.67 23.5733 1889.17 50.083 1889.17H1199.84C1226.35 1889.17 1247.84 1910.66 1247.84 1937.17V2470.13C1247.84 2496.64 1226.35 2518.13 1199.84 2518.13H50.0829C23.5732 2518.13 2.08296 2539.62 2.08296 2566.13V3117.27C2.08296 3143.78 23.5733 3165.27 50.083 3165.27H572.147C598.657 3165.27 620.147 3186.76 620.147 3213.27V3692.21"
  stroke={stroke}
  className={`motion-line ${pathClass}`}
  strokeWidth="4"
  strokeLinecap="round"
/>
</svg> */
}

// Former code for mobile svg
// <div className={` w-full ${className}  flex-col items-center absolute hidden -1024:flex`}>
// <svg
//   viewBox="0 0 362 4593"
//   fill="none"
//   preserveAspectRatio="none"
//   xmlns="http://www.w3.org/2000/svg"
//   className={`w-full !h-[100%] hidden -1024:block`}
// >
//   <path
//     d="M179.035 1.8276V217.716C179.035 235.389 164.708 249.716 147.035 249.716L33.4222 249.716C15.7491 249.716 1.42224 264.043 1.42224 281.716L1.42224 713.46C1.42224 731.133 15.7491 745.46 33.4222 745.46H259.91H328.324C345.997 745.46 360.324 759.787 360.324 777.46V1500.58C360.324 1518.25 345.997 1532.58 328.324 1532.58H33.4223C15.7491 1532.58 1.42225 1546.91 1.42225 1564.58V2316.49C1.42225 2334.17 15.7491 2348.49 33.4222 2348.49H328.324C345.997 2348.49 360.324 2362.82 360.324 2380.49V3098.81C360.324 3116.49 345.997 3130.81 328.324 3130.81H33.4223C15.7491 3130.81 1.42225 3145.14 1.42225 3162.81V3903.73C1.42225 3921.41 15.7491 3935.73 33.4222 3935.73H147.486C165.159 3935.73 179.486 3950.06 179.486 3967.73V4591.14"
//     stroke={stroke}
//     className={`motion-line ${pathClass}`}
//     strokeWidth="2"
//     strokeLinecap="round"
//   />
// </svg>

// <div className="bg-[white] absolute translate-x-[-2px] z-[5] bottom-0 -600:h-[12px] -600:w-[12px] h-[15px] w-[15px] rounded-full -1024:block hidden"></div>
// </div>
