import React from "react";

export default function ProductTick({
  fill,
  className,
}: {
  fill: string;
  className?: string;
}) {
  return (
    <svg
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M8.6 12.5856L6.45 10.4356C6.26667 10.2523 6.03333 10.1606 5.75 10.1606C5.46667 10.1606 5.23334 10.2523 5.05 10.4356C4.86667 10.619 4.775 10.8523 4.775 11.1356C4.775 11.419 4.86667 11.6523 5.05 11.8356L7.9 14.6856C8.1 14.8856 8.33333 14.9856 8.6 14.9856C8.86667 14.9856 9.1 14.8856 9.3 14.6856L14.95 9.03564C15.1333 8.85231 15.225 8.61898 15.225 8.33564C15.225 8.05231 15.1333 7.81898 14.95 7.63564C14.7667 7.45231 14.5333 7.36064 14.25 7.36064C13.9667 7.36064 13.7333 7.45231 13.55 7.63564L8.6 12.5856ZM10 20.7856C8.61667 20.7856 7.31667 20.523 6.1 19.9976C4.88334 19.4723 3.825 18.76 2.925 17.8606C2.025 16.9613 1.31267 15.903 0.788001 14.6856C0.263335 13.4683 0.000667933 12.1683 1.26582e-06 10.7856C-0.000665401 9.40298 0.262001 8.10298 0.788001 6.88564C1.314 5.66831 2.02633 4.60998 2.925 3.71064C3.82367 2.81131 4.882 2.09898 6.1 1.57364C7.318 1.04831 8.618 0.785645 10 0.785645C11.382 0.785645 12.682 1.04831 13.9 1.57364C15.118 2.09898 16.1763 2.81131 17.075 3.71064C17.9737 4.60998 18.6863 5.66831 19.213 6.88564C19.7397 8.10298 20.002 9.40298 20 10.7856C19.998 12.1683 19.7353 13.4683 19.212 14.6856C18.6887 15.903 17.9763 16.9613 17.075 17.8606C16.1737 18.76 15.1153 19.4726 13.9 19.9986C12.6847 20.5246 11.3847 20.787 10 20.7856Z"
        fill={fill}
      />
    </svg>
  );
}
