"use client";

import React, { useEffect, useState } from "react";
import paal_bots from "../../public/images/paal_labs.svg";
import arrow_down from "../../public/images/arrow-down.svg";
import android from "../../public/videos/android.gif";
import Image from "next/image";
import gsap from "gsap";

export default function ResearchDev() {
  const [readMore, setReadMore] = useState(false);

  useEffect(() => {
    const mm = gsap.matchMedia();

    // If the screen size is less than 768px set readmore to true by default
    mm.add("(max-width: 768px)", () => {
      setReadMore(true);
    });
    mm.add("(min-width: 768px)", () => {
      setReadMore(false);
    });
  }, []);

  return (
    <section
      id="research"
      className={`mt-[136px] bg-[#6632D8] pt-[144px] pb-[159px] px-[125px] -1200:px-[50px] flex items-center -600:px-[20px] -600:pt-[58px] ${
        readMore ? "-600:pb-[189px]" : ""
      } relative`}
    >
      <div className="flex justify-between items-center w-full max-w-[1700px] mx-auto">
        {/* Container for typography and logo */}
        <div className="flex flex-col gap-[32px] items-start justify-start relative">
          {/* Image */}
          <Image src={paal_bots} alt="icon" className="-600:w-[145px]" />

          {/* HEading */}
          <h2 className="heading z-[3] relative">
            AI Research and Development
          </h2>
          <p className="leading-[156%] max-w-[702px] font-normal tracking-wide text-[20px ] -600:text-[16px] -600:mt-[-20px] z-[3] relative">
            Paal Labs is at the forefront of innovation, harnessing the power of
            deep learning technology and large language models to construct and
            fine-tune generative models. These models utilize vast quantities of
            data to train AI systems, enabling them to perform a wide array of
            tasks with precision and efficiency. 🤖💡 <br /> <br />
            {!readMore ? (
              <>
                Our team is dedicated to the development and testing of
                autonomous AI assistant frameworks and agent swarms. These
                sophisticated systems are designed to ensure our AI bots deliver
                the most accurate and relevant responses, and behaviour for our
                customers. 🎯👥 <br /> <br />
                Moreover, our AI bots are capable of executing highly complex
                tasks and workflows, providing our customers with a seamless and
                efficient experience. We are committed to pushing the boundaries
                of AI technology safely and responsibly to deliver the best
                possible solutions for our clients. 💼🚀 <br /> <br />
                As we approach AGI, Paal is preparing our clients now for how to
                safely integrate AGI into their products and services via AI
                bots/ assistants to maximize potential and mitigate operational
                risks. 
              </>
            ) : (
              ""
            )}
          </p>

          {/* Show less btn */}
          {!readMore ? (
            <button
              onClick={() => {
                setReadMore(!readMore);
              }}
              className="-768:flex flex-col items-center justify-center self-center translate-y-[20px] gap-[8px] hidden "
            >
              <Image
                src={arrow_down}
                alt="arroww"
                className="rotate-[180deg]"
              />
              <p className="font-semibold tracking-wider">Show Less</p>
            </button>
          ) : (
            ""
          )}
        </div>

        {/* Gradient for mobile */}
        {readMore ? (
          <div className="absolute inset-0 z-[2] h-full w-full research-gradient">
            <button
              onClick={() => {
                setReadMore(!readMore);
              }}
              className="flex flex-col items-center justify-center gap-[8px] absolute bottom-[113px] center-x"
            >
              <Image src={arrow_down} alt="arroww" />
              <p className="font-semibold tracking-wider">Expand Information</p>
            </button>
          </div>
        ) : (
          ""
        )}

        {/* Android gif */}
        <Image
          src={android}
          alt="robot"
          className="h-[613px] object-center object-contain -1024:object-cover -1024:absolute right-0 top-[-70px] -1024:h-[250px] -1024:w-[209px]"
        />
      </div>
    </section>
  );
}
