"use client";

import React from "react";
import logo from "../../public/images/logo.png";
import Image from "next/image";
import bitmart from "../../public/images/bitmart.png";
import gecko_icon from "../../public/images/gecko_icon.png";
import github from "../../public/images/github.svg";
import twitter from "../../public/images/twitter.svg";
import telegram from "../../public/images/telegram.svg";
import discord from "../../public/images/discord.svg";
import Link from "next/link";
import { usePathname, useRouter } from "next/navigation";
import gsap from "gsap";

export default function Footer() {
  const pathname = usePathname();
  const router = useRouter();
  const socials = [
    {
      link: "https://www.bitmart.com/trade/en-US?symbol=PAAL_USDT",
      icon: bitmart,
    },
    {
      link: "https://www.geckoterminal.com/eth/pools/0x2a6c340bcbb0a79d3deecd3bc5cbc2605ea9259f",
      icon: gecko_icon,
    },
    { link: "https://github.com/", icon: github },
    { link: "https://x.com/PaalMind", icon: twitter },
    { link: "https://t.me/paal_ai", icon: telegram },
    { link: "https://discord.com/invite/paalai", icon: discord },
  ];

  const list1 = [
    { name: "Products", link: "/#products", id: "#products" },
    { name: "Services", link: "/#services", id: "#services" },
    { name: "Partners", link: "/#partners", id: "#partners" },
    { name: "Research", link: "/#research", id: "#research" },
  ];

  const list2 = [
    { name: "Safety", link: "/#safety", id: "#safety" },
    { name: "Roadmap", link: "/roadmap" },
    { name: "Blog", link: "/blog" },
    { name: "Videos", link: "/videos" },
  ];

  const list3 = [
    {
      name: "Acceptable Use",
      link: "https://docs.paal.ai/policies/acceptable-use-policy",
    },
    {
      name: "Cookie Policy",
      link: "https://docs.paal.ai/policies/cookie-policy",
    },
    {
      name: "Privacy Policy",
      link: "https://docs.paal.ai/policies/privacy-policy",
    },
    {
      name: "Terms of Service",
      link: "https://docs.paal.ai/policies/terms-of-service",
    },
  ];

  function ScrollToSection(e: React.MouseEvent) {
    const target = e.currentTarget as HTMLElement;

    if (!target.dataset.id) return;

    if (pathname !== "/") {
      router.push(`/${target.dataset.id}`);
      return;
    }

    e.preventDefault();
    gsap.to(window, {
      duration: 1.5,
      scrollTo: {
        y: `${target.dataset.id}`,
        offsetY: 100,
      },
      ease: "power2.inOut",
    });
  }

  return (
    <footer className="mt-[92px] w-full max-w-[1240px] px-[20px] pb-[107px] -600:pb-[48px]  mx-auto flex justify-between items-center -968:flex-col -968:items-start -968:gap-[72px]">
      {/* Conainer for logo and paragraph */}
      <div className="flex flex-col gap-[22px] max-w-[394px] items-start">
        <Link href="/">
          <Image src={logo} alt="logo" className="max-w-[160px]" />
        </Link>

        <p className="leading-[137.5%] max-w-[380px] font-medium">
          Create Advanced AI Applications using Paal. There are no limits to
          what you can create.
        </p>

        <p className="font-medium max-w-[371px]  leading-[137.5%] text-[#FFFFFF99]">
          You can email{" "}
          <a href="mailto:admin@paalai.io" className="text-white underline">
            admin@paalai.io
          </a>{" "}
          to provide feedback for support requests.
        </p>

        {/* Container for icons */}
        <div className="flex items-center justify-center gap-[46px] -968:hidden">
          {socials.map((social, idx) => {
            return (
              <a target="_blank" key={idx} href={social.link}>
                <Image src={social.icon} alt="icon" />
              </a>
            );
          })}
        </div>
      </div>

      {/* Container for page links, terms, privacy policy, etc */}
      <div className="flex gap-[107px] -968:gap-[32px] -600:text-[14px] flex-wrap items-center -600:justify-between -600:w-full -600:max-w-[475px] font-medium">
        {/* List 1 */}
        <ul className="flex flex-col gap-[29px]">
          {list1.map((item, idx) => {
            return (
              <Link
                key={idx}
                className="footer-link relative"
                href={item.link}
                data-id={item.id}
                onClick={ScrollToSection}
              >
                {item.name}
              </Link>
            );
          })}
        </ul>

        {/* List 2 */}
        <ul className="flex flex-col gap-[29px]">
          {list2.map((item, idx) => {
            return (
              <Link
                key={idx}
                className="footer-link relative"
                href={item.link}
                data-id={item.id}
                onClick={ScrollToSection}
              >
                {item.name}
              </Link>
            );
          })}
        </ul>

        {/* List 3 */}
        <ul className="flex flex-col gap-[29px]">
          {list3.map((item, idx) => {
            return (
              <Link
                target="_blank"
                key={idx}
                className="footer-link relative"
                href={item.link}
              >
                {item.name}
              </Link>
            );
          })}
        </ul>
      </div>

      {/* Container for icons */}
      <div className="-968:flex items-center justify-center gap-[38px] flex-wrap hidden mt-[-24px]">
        {socials.map((social, idx) => {
          return (
            <a key={idx} href={social.link}>
              <Image src={social.icon} className="max-w-[24px]" alt="icon" />
            </a>
          );
        })}
      </div>
    </footer>
  );
}
