import React, { CSSProperties, ReactNode } from "react";

interface BtnTypes {
  hasBorder?: boolean;
  className: string;
  children: ReactNode;
  style?: CSSProperties;
  outerClass?: string;
  isMob?: boolean;
}

export default function Button({
  hasBorder = false,
  className,
  children,
  style,
  outerClass,
  isMob = false,
}: BtnTypes) {
  return (
    <button
      className={`${
        hasBorder ? "btn-left-conic-gradient" : ""
      }  rounded-[6px] font-medium p-[1px] relative z-[1] w-fit ${
        !isMob ? "btn-hover" : ""
      } ${outerClass}`}
    >
      <div
        style={style}
        className={`py-[12px] rounded-[6px] relative z-[3] -600:rounded-[4px] ${className}`}
      >
        {children}
      </div>
    </button>
  );
}
